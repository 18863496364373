<template>
  <div>
    <!--begin::Head-->
    <div class="kt-login__head">
      <span class="kt-login__signup-label">{{
        $t("AUTH.GENERAL.NO_ACCOUNT")
      }}</span
      >&nbsp;&nbsp;
      <router-link
        class="kt-link kt-login__signup-link"
        :to="{ name: 'register' }"
      >
        {{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}
      </router-link>
    </div>
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Sign In</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <div v-if="errors">
            <div
              role="alert"
              v-bind:class="{ show: errors.length }"
              class="alert fade alert-danger"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>
          </div>

          <b-form-group
            id="example-input-group-1"
            label=""
            label-for="example-input-1"
          >
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Email is required and a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="example-input-group-2"
            label=""
            label-for="example-input-2"
          >
            <b-form-input
              type="password"
              id="example-input-2"
              name="example-input-2"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-2-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback">
              Password is required.
            </b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <b-link
              :to="{ path: '/forgot' }"
              class="kt-link kt-login__link-forgot"
            >
              {{ $t("AUTH.FORGOT.TITLE") }}
            </b-link>
            <button
              type="submit"
              id="kt_submit"
              class="btn btn-primary btn-elevate kt-login__btn-primary"
            >
              {{ $t("AUTH.LOGIN.BUTTON") }}
            </button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->

        <!--begin::Divider-->
        <div class="kt-login__divider">
          <div class="kt-divider">
            <span></span>
            <span>OR</span>
            <span></span>
          </div>
        </div>
        <!--end::Divider-->

        <!--begin::Options-->
        <div class="kt-login__options">
          <a :href="sso_link('facebook')" class="btn btn-primary kt-btn">
            <i class="fab fa-facebook-f"></i>
            Facebook
          </a>

          <a :href="sso_link('twitter')" class="btn btn-info kt-btn">
            <i class="fab fa-twitter"></i>
            Twitter
          </a>

          <a :href="sso_link('google')" class="btn btn-danger kt-btn">
            <i class="fab fa-google"></i>
            Google
          </a>
        </div>
        <!--end::Options-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import Vue from "vue";
import ApiService from "@/common/api.service";
import { API_URL } from "@/common/config.js";
import { mapState } from "vuex";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { notif, ApiURL } from "@/common/mixins/general.js";
import { validationMixin } from "vuelidate";
import { LOGIN, LOGOUT, LOGIN_SSO } from "@/store/auth.module";
import { email, minLength, required } from "vuelidate/lib/validators";
import { reCaptcha } from "@/common/config.js";

Vue.use(VueReCaptcha, { siteKey: reCaptcha.v3.site_key });

export default {
  mixins: [validationMixin, notif, ApiURL],
  name: "login",
  data() {
    return {
      form: {
        email: "satu@onewaymail.com",
        password: "kalkulus"
      },
      apiurl: API_URL
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /**
     * --------------------------------------------------
     * reset form login
     * --------------------------------------------------
     */
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    /**
     * --------------------------------------------------
     * submit data login
     * --------------------------------------------------
     */
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // jika recaptcha oke
      this.$recaptcha("login").then(token => {
        const username = this.$v.form.email.$model;
        const password = this.$v.form.password.$model;
        const reCaptcha = token;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = document.getElementById("kt_submit");
        submitButton.classList.add(
          "kt-spinner",
          "kt-spinner--light",
          "kt-spinner--right"
        );

        // send login request
        this.$store
          .dispatch(LOGIN, { username, password, reCaptcha })
          .then(() => {
            this.$recaptchaInstance.hideBadge();
            this.$router.push({ name: "dashboard" });
          })
          .catch(() => {
            submitButton.classList.remove(
              "kt-spinner",
              "kt-spinner--light",
              "kt-spinner--right"
            );
          });
      });
    },
    sso_link: function(sosmed) {
      return `${this.apiurl}/auth/sso/${sosmed}`;
    },
    sso_verify: function() {
      ApiService.setHeader();
      ApiService.get(this.api.auth.verify)
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    }
  },
  mounted() {
    /**
     * ------------------------------------------------
     * proses untuk yg login menggunakan sso
     * ------------------------------------------------
     */
    let query = this.$route.query;
    if (typeof query.token !== "undefined") {
      if (query.token !== "false") {
        this.$store.dispatch(LOGIN_SSO, query.token).then(() => {
          this.sso_verify();
        });
      } else {
        if (query.error === "Email tidak ditemukan") {
          this.mx_swal(query.error);
        }
      }
    }
  }
};
</script>
